<template>
  <div class="sectionWrapper">
    <div class="top">
      <img src="../assets/images/designs/up-convex.svg" alt="" />
    </div>
    <div class="content">
      <h1>
        2 große Trainingsarten von leamos - Betreutes Lesen & Einzeltraining
      </h1>
      <div class="section">
        <div class="text">
          <h3>Betreutes Lesen – gemeinsam mit einem Lesepartner laut lesen</h3>
          <p>
            Für Leseanfänger und schwache Leser ist es einfacher, wenn sie sich
            selbst beim Lesen hören und sich so selbst kontrollieren können.
            Außerdem gibt der Lesepartner ein direktes Feedback über die
            Richtigkeit der gelesenen Worte und Pseudoworte.
          </p>
        </div>

        <div class="image">
          <img src="../assets/images/leamos_betreut.jpg" alt="" />
        </div>
      </div>
      <div class="section">
        <div class="image">
          <img src="../assets/images/leamos_einzel.jpg" alt="" />
        </div>
        <div class="text">
          <h3>Einzeltraining – Leise Lesen für sich selbst trainieren</h3>
          <p>
            Acht verschiedene Trainingsarten des Einzeltrainings verbessern die
            Lesekompetenz weiter und trainieren die Visualisierung der Worte
            variantenreich. Eigenständiges Arbeiten ohne die Anwesenheit von
            Betreuern erhöht dabei die Selbstsicherheit.
          </p>
        </div>
      </div>
      <!-- <div class="reason">
            <div class="number">
                <img src="../assets/images/super.svg" alt="">
            </div>
            <div class="text">
                <h3>Betreutes Lesen – gemeinsam mit einem Lesepartner laut lesen</h3>
                <p>Für Leseanfänger und schwache Leser ist es einfacher, wenn sie sich selbst beim Lesen hören und sich so selbst kontrollieren können. Außerdem gibt der Lesepartner ein direktes Feedback über die Richtigkeit der gelesenen Worte und Pseudoworte.</p>
            </div>
        </div>
        <div class="reason">
            <div class="number">
                <img src="../assets/images/leamos_einzel.jpg" alt="">
            </div>
            <div class="text">
                <h3>Einzeltraining – Leise Lesen für sich selbst trainieren </h3>
                <p>Acht verschiedene Trainingsarten des Einzeltrainings verbessern die Lesekompetenz weiter und trainieren die Visualisierung der Worte variantenreich. Eigenständiges Arbeiten ohne die Anwesenheit von Betreuern erhöht dabei die Selbstsicherheit.</p>
            </div>
        </div> -->
      <h3 style="width: 100%">
        Willst du noch mehr Details wissen? Auf der nächsten Seite erfährst du
        mehr.
      </h3>
      <br />
      <br />
      <router-link to="/schulen#details" style="margin: auto">
        <div
          style="
            cursor: pointer;
            background-color: #f5f5f5;
            outline: none;
            border: 0px;
            padding: 5px 10px;
            border-radius: 5px;
            box-shadow: 0 0 10px 2px lightgray;
          "
        >
          <h4 style="color: black; margin: 4px; text-align: center">
            Mehr erfahren
          </h4>
        </div>
      </router-link>
    </div>
    <div class="bottom">
      <img src="../assets/images/designs/down-convex.svg" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ModesSection",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/styles/variables";
.sectionWrapper {
  background-color: #b9eabb;
  .top {
    background-color: #f5f5f5;
    width: 100%;
    height: 120px;
    img {
      width: 100%;
      height: 120px;
    }
  }
  .bottom {
    background-color: #f5f5f5;
    width: 100%;
    height: 120px;
    img {
      width: 100%;
      height: 120px;
    }
  }
  .content {
    padding-top: 1px;
    padding-bottom: 80px;
    width: 70vw;
    margin: auto;
    z-index: 2;
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: $start-mobile) {
      width: 90vw;
    }
    .section {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      &:nth-child(even) {
        flex-wrap: wrap-reverse;
      }
      .image {
        display: flex;
        align-items: center;
        // justify-content: center;
        width: 70%;
        max-width: 500px;
        margin: auto;
        @media screen and (max-width: $small-pre-mobile) {
          width: 50%;
        }
        @media screen and (max-width: 800px) {
          width: 100%;
        }
        img {
          // max-width: 500px;
          // width: 100%;
          max-height: 250px;
          margin: auto;

          box-shadow: 1px 1px 7px #2c3e50;
          border-radius: 8px;
          margin-bottom: 10px;
        }
      }
      .text {
        width: calc(100% - 500px);
        @media screen and (max-width: $small-pre-mobile) {
          width: 45%;
          margin-right: 5%;
          &:nth-child(even) {
            margin-left: 5%;
            margin-right: 0px;
          }
        }
        @media screen and (max-width: 800px) {
          width: 100%;
        }
        text-align: center;
        display: flex;
        /* align-items: center; */
        flex-direction: column;
        justify-content: center;
      }
    }

    // .reason{
    //     display: flex;
    //     text-align: left;
    //     width: 60%;
    //     @media screen and (max-width: 800px) {
    //               width: 100%;
    //           }
    //     .number{
    //         font-size: 6rem;
    //         font-weight: bolder;
    //         display: flex;
    //         align-items: center;
    //         // width: 150px;
    //         // max-height: 200px;
    //         // max-width: 200px;
    //         width: 25%;
    //         img{
    //           //   height: 100%;
    //             width: 100%;
    //         }
    //     }
    //     .text{
    //         padding-left: 20px;
    //         max-width: 75%;
    //         min-width: 200px;
    //         @media screen and (max-width: 800px) {
    //         &:nth-of-type(even){
    //           padding-right: 20px;
    //           padding-left: 0px;
    //         }
    //         }
    //         // &:nth-of-type(odd){
    //         //   padding-left: 20px;
    //         // }

    //     }
    //     &:nth-of-type(even){
    //         margin-left: auto;
    //         @media screen and (max-width: 800px) {
    //               margin-left: 0px;
    //               flex-direction: row-reverse;
    //           }
    //     }
    // }
  }
}
</style>
