<template>
  <div class="sectionWrapper"  name="details" id="details">
      <div class="top">
      <img src="../assets/images/designs/up-convex.svg" alt="" />
    </div>
    <div class="content">
      
           <h1>leamos bietet noch viel mehr:</h1>
        <div class="reason">
            <div class="number">
                <img src="../assets/images/word_cloud.svg" alt="">
            </div>
            <div class="text">
                <h3>Umfassende Übungsdatenbank mit vielen Trainingslisten</h3>
                <p><strong>
                    1780 Trainingslisten</strong> mit jeweils 20 bis 60 Aufgaben sind das große Übungsreservoire im Hintergrund. Die Listen weisen 150 verschiedene, für das Deutsche typische Wortstrukturen auf. Leamos verwendet hauptsächlich den Grundwortschatz der Grund- bzw. Volksschule. Jede Trainingsliste verfolgt ein spezielles Ziel.
                </p>
            </div>
            </div>
        <div class="reason">
            <div class="number">
                <img src="../assets/images/algorithm.svg" alt="">
            </div>
            <div class="text">
                <h3>Berechnung des Schwierigkeitsgrades</h3>
                <p>
                    Algorithmen berechnen das Schwierigkeitsniveau aufgrund mehrerer Parameter: 
                    <ul>
                        <li>
                            <strong>Art</strong> der <strong>Worte</strong> (Pseudoworte oder natürliche Worte) oder <strong>Symbole</strong>,
                        </li>
                        <li>
                            <strong>
                                Wortlänge</strong> (2 bis 10 und mehr Buchstaben), 
                        </li>
                        <li>
                            <strong>Art</strong> der <strong>Mitlautkombinationen</strong> (und das Vorkommen am Wortanfang, in der Mitte oder am Ende),  
                        </li>
                        <li>
                            <strong>Wortstruktur</strong> (Silbentrennung, Vor- und Nachsilben, zusammengesetzte Worte)
                        </li>
                        <li>
                            <strong>Art der Darstellung </strong> am Bildschirm (geblockt, auseinander oder in einer Zeile)

                        </li>
                        <li>
                          Symbole mit oder ohne <strong>Wiederholungen</strong>
                        </li>
                    </ul>
                    
                    <!-- Algorithmen berechnen den Schwierigkeitslevel aufgrund folgender Parameter: Die <strong>Art</strong> der <strong>Worte</strong> (Quatschworte oder natürliche Worte) oder <strong>Symbole</strong> spielen ebenso eine Rolle wie deren <strong>Länge</strong> (zwischen 2 bis 10 und mehr Buchstaben). Die <strong>Art</strong> der <strong>Mitlautkombinationen</strong> und der <strong>Wortstruktur</strong> fließen gleichfalls in die in Berechnung der Schwierigkeiten ein. Ebenso wie die <strong>Art der Darstellung</strong> am Bildschirm die Schwierigkeit beeinflusst.  -->
                </p>
            </div>
        </div>
        <div class="reason">
            <div class="number">
                <img src="../assets/images/brain.svg" alt="">
            </div>
            <div class="text">
                <h3>Du bist fähig dein Gehirn zu verbessern</h3>
                <p>
                    Im <strong>Trainingspool</strong> des Einzeltrainings befinden sich immer 20 Übungslisten. Diese sorgen für ständig wechselnde Aufgaben. Das Gehirn bleibt neugierig und lernt so besser. Jede Trainingsliste bleibt solange im Trainingspool und wird immer wieder aufgerufen bis <strong>sie dreimal mit einem Erfolg von über 75%</strong> erledigt wurde. leamos aktiviert durch dieses Konzept verstärkt die Neuroplastizität, die <strong>positive Veränderung des Gehirns</strong>, indem es das Prinzip der <strong>häufigen Wiederholung</strong> mit jenem der <strong>ständigen Abwechslung</strong> verknüpft. Ein motiviertes Gehirn lernt schneller.
                </p>
            </div>
        </div>
        <div class="reason">
            <div class="number">
                <img src="../assets/images/book.svg" alt="">
            </div>
            <div class="text">
                <h3>Automatisierung und Visualisierung</h3>
                <p>
                    Die Automatisierung der Worterkennung auf einen Blick erfolgt durch die vielen Wiederholungen ähnlicher Wortstrukturen. Das Arbeitsgedächtnis wird dadurch zunehmend entlastet. leamos arbeitet intensiv an der Visualisierung der Wortbilder, Trainiert wird beispielweise mit der Liste der 100 häufigsten Fehlerworte im Deutschen. Die Lesegenauigkeit und Lesegeschwindigkeit nehmen zu und verbessern stetig die Lesekompetenz. 
                </p>
            </div>
        </div>
        <div class="reason">
            <div class="number">
                <img src="../assets/images/flash.svg" alt="">
            </div>
            <div class="text">
                <h3>Blitzlesen – du wirst immer schneller im Erkennen</h3>
                <p>
                   12 unterschiedliche Anzeigezeiten werden für dich permanent ermittelt. Die Anzeigezeit variiert in der einzelnen Trainingsart zwischen 200 ms und 3 sec. Je nach dem erzielten Erfolg (% Richtigkeit) wird die Anzeigezeit in der jeweiligen Trainingsart für das nächste Mal verlängert oder verkürzt. 
                </p>
            </div>
        </div>
        <div class="reason">
            <div class="number">
                <img src="../assets/images/buttons.svg" alt="">
            </div>
            <div class="text">
                <h3>Farblos und fokussiert</h3>
                <p>
                    leamos ist Lesen üben ohne farbliche Ablenkung. Lesen lernen bedeutet Arbeit.  leamos verzichtet auf Leseübungen, die als Spiele verkleidet sind. leamos konzentriert sich auf den Leseprozess im engeren Sinn, die Visualisierung und die auditive sowie visuelle Merkfähigkeit. Gamification wird in leamos rund um die Trainings verwendet und motiviert täglich zu arbeiten. 
                </p>
            </div>
        </div>
        <div class="reason">
            <div class="number">
                <img src="../assets/images/dyslexia.svg" alt="">
            </div>
            <div class="text">
                <h3>Legastheniker und Leseschwache brauchen besonders viele Wiederholungen mit geringen Variationen </h3>
                <p>
                    Hier liegt eine weitere <strong>Stärke von leamos</strong>. Bei einer diagnostizierten Legasthenie, einer Lese-Rechtschreibschwäche oder einer Schwäche der sensomotorischen Basisfähigkeiten bietet leamos eine sehr gute Unterstützung. Viele Trainingsarten orientieren sich an den <strong>Bedürfnissen von legasthenen Gehirnen</strong>. Leseanforderungen werden isoliert trainiert, mit minimalen Variationen wiederholt und die Schwierigkeiten werden nur in kleinen Stufen erhöht.
                </p>
            </div>
        </div>
        <div class="reason">
            <div class="number">
                <img src="../assets/images/body.svg" alt="">
            </div>
            <div class="text">
                <h3>Leseförderung mit dem ganzen Körper</h3>
                <p>Der Lesevorgang benötigt die abgestimmte Zusammenarbeit vieler Gehirnzentren. Mangelhafte Steuerung der Augenbewegungen, ungenaue Verarbeitung der gehörten Sprache, fehlende zeitliche Koordination zwischen Sehen und Hören sowie mangelhaftes Gleichgewicht sind Beispiele wie die Sensomotorik die Lesefähigkeit negativ beeinflussen kann.  
                </p>
                <p>
                    Nähere Informationen unter <a href="https://www.mtl-zentrum.at" target="_blank">www.mtl-zentrum.at</a> oder auch im Buch „Herausforderung Schule – was hat Bewegung mit erfolgreichem Lernen zu tun“. Den Erfolg von Leseförderung mittels körperlichen Trainings in der Schule konnte das Projekt „Simmo - Lesen kommt in Bewegung“ gezeigt werden. Die sehr positiven Ergebnisse können unter <a href="https://www.talente-bewegen.at" target="_blank">www.talente-bewegen.at</a> eingesehen werden.
                </p>
            </div>
        </div>
    
    </div>
    <div class="bottom">
      <img src="../assets/images/designs/down-convex.svg" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "SchoolDetails",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/styles/variables";
.sectionWrapper {
  background-color: #b9eabb;
  .top {
    background-color: #f5f5f5;
    width: 100%;
    height: 120px;
    img {
      width: 100%;
      height: 120px;
    }
  }
  .bottom {
    background-color: #f5f5f5;
    width: 100%;
    height: 120px;
    img {
      width: 100%;
      height: 120px;
    }
  }
  .content {
    padding-top: 1px;
    padding-bottom: 80px;
    width: 70vw;
    margin: auto;
    z-index: 2;
    @media screen and (max-width: $start-mobile) {
      width: 90vw;
    }
    .reason {
      display: flex;
      text-align: left;
      width: 60%;
      @media screen and (max-width: 800px) {
        width: 100%;
      }
      .number {
        font-size: 6rem;
        font-weight: bolder;
        display: flex;
        align-items: center;
        // width: 150px;
        // max-height: 200px;
        // max-width: 200px;
        width: 25%;
        img {
          //   height: 100%;
          width: 100%;
        }
      }
      .text {
        padding-left: 20px;
        max-width: 75%;
        min-width: 200px;
        @media screen and (max-width: 800px) {
          &:nth-of-type(even) {
            padding-right: 20px;
            padding-left: 0px;
          }
        }
        // &:nth-of-type(odd){
        //   padding-left: 20px;
        // }
      }
      &:nth-of-type(even) {
        margin-left: auto;
        @media screen and (max-width: 800px) {
          margin-left: 0px;
          flex-direction: row-reverse;
        }
      }
    }
  }
}
</style>
