<template>
  <div class="sectionWrapper">
    <div class="content">
      <div class="section">
        <div class="image">
        <img src="../assets/images/betreutesLesen.png" alt="" />
      </div>
      <div class="text">
        <h2 >Betreutes Lesen</h2>
        <!-- <h3 style="margin-top: 0px;">komplett variabel & ständiger Wechsel möglich.</h3> -->
        <p>
          Beim Betreuten Lesen wird dem Lesepartner laut vorgelesen. Der Lesepartner gibt dem Lesenden ein sofortiges Feedback.
  <ul>
    <li>Training der <strong>Lesegenauigkeit</strong> und des indirekten Leseweges mit Pseudoworten
im Wechsel mit</li>
<li>
  Training der <strong>Sichtworterkennung</strong> mit <strong>natürlichen Worten</strong> und des direkten Leseweges. Damit wird auch die <strong>Lesegeschwindigkeit</strong> verbessert.
</li>

  </ul>
  leamos steigert den Schwierigkeitsgrad langsam.  Algorithmen berechnen die passende Schwierigkeit im Hintergrund. Betreuer,  Pädagog*innen haben keinen Aufwand bei der Erstellung der Trainings.


        </p>

      </div>
      </div>
      <div class="section">
        
      <div class="text">
          <h2>Einzeltraining</h2>
          <p>
              Selbstständiges Arbeiten mit acht verschiedenen Trainingsarten verbessert Bereiche wie 
              <ul>
                <li>Fokussiertes Achten auf <strong>Wortmerkmale</strong></li>
                <li>Training des <strong>visuellen Gedächtnisses</strong> mit Symbolen</li>
                <li>Vergleichen von <strong>Schreibweisen</strong></li>
                <li>Training der häufigsten <strong>Mitlautkombinationen</strong> sowie <strong>Vorsilben</strong> und <strong>Endungen</strong></li>
                <li>Automatisierung der <strong>Worterkennung</strong></li>
                <!-- <li>Unterscheidung wortähnlicher Pseudoworte von natürlichen Worten</li> -->
                <li><strong>Visualisierung</strong> der Wortbilder</li>
                <li>Erkennen der <strong>richtigen Schreibweisen</strong></li>
                <li>Aktives <strong>Abrufen und Tippen</strong> von Symbolabfolgen, Pseudoworten oder natürlichen Worten</li>
                <li>
                  <strong>Anwendung der Lesekompetenz</strong> auf Wörterbetrachtungen
                </li>
              </ul>
          </p>
      </div>
      <div class="image">
        <img src="../assets/images/trainings.png" alt="" />
      </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Responsive",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/styles/variables";
.sectionWrapper {
  background-color: #f5f4f4bb;
  .content {
    width: 70vw;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: $start-mobile) {
      width: 90vw;
    }
    .section {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      &:nth-child(2) {
        flex-wrap: wrap-reverse;
      }
      .image {
        display: flex;
        align-items: center;
        width: 70%;
        max-width: 500px;
        margin: auto;
        @media screen and (max-width: $small-pre-mobile) {
          width: 50%;
        }
        @media screen and (max-width: 800px) {
          width: 100%;
        }
        img {
          // max-width: 500px;
          width: 100%;
          max-height: 300px;
          object-fit: contain;
        }
      }
      .text {
        width: calc(100% - 500px);
        @media screen and (max-width: $small-pre-mobile) {
          width: 50%;
        }
        @media screen and (max-width: 800px) {
          width: 100%;
        }
        text-align: justify;
      }
    }
  }
}
</style>
