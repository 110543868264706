<template>
  <div>
      <h1>Impressum</h1>
      <h2>Seiteninhaber:</h2>
      <h4>Kerensstraße 14 <br>3100 St. Pölten <br>Österreich</h4>

      <h2>      Haftungsausschluss und rechtliche Hinweise
</h2>
<p>
   Alle Rechte vorbehalten.  
</p>
<p>Alle Texte der MTL-Website wurden gründlich und gewissenhaft geprüft. Dennoch übernimmt der Inhaber keine Haftung und Garantie für die Richtigkeit, Aktualität und Vollständigkeit der Inhalte.
</p>
<p>Alle Angaben beziehen sich auf Frauen und Männer gleichermaßen. Aufgrund der leichteren Lesbarkeit wurde auf eine durchgängige geschlechtsneutrale Schreibweise verzichtet.
</p>
 <p>
     Alle Inhalte dieser Web-Site unterliegen dem Urheberrecht und werden den Besuchern ausschließlich als Informationsquelle bereitgestellt. Jegliches Kopieren, Vervielfältigen und Verwenden von Daten und Informationen dieser Homepage ist ohne ausdrückliche und schriftliche Genehmigung durch den Seiteninhaber oder den Autor verboten und strafbar. Dies betrifft auch die gewerbliche Nutzung der Inhalte und Daten. 

 </p>
<p>
    Links zu Webseiten Dritter werden Ihnen auf dieser Webseite als reine Serviceleistung bzw. als Hinweis angeboten. Für die Inhalte, zu denen verlinkt wird, sind die Anbieter der jeweiligen Webseiten selbst verantwortlich. Auch haftet der Seiteninhaber nicht für mögliche Schäden die durch die gelinkten Seiten entstehen könnten, oder aber auch durch die Inhalte dieser Homepage. Zusätzlich teilt der Seiteninhaber nicht immer die Meinung von Inhalten die nicht durch ihn sondern durch andere hinzugefügt wurde.

</p>
<p>Gerichtsstand: St. Pölten
</p>
 


 


 


 

  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/styles/variables";
    div{
        width: 70vw;
        margin: auto;
        text-align: left;
            @media screen and (max-width: $start-mobile) {
      width: 90vw;
    }
    }
</style>
