<template>
  <div class="home">
    <CoverConcept />
    <Animal />
    <FeatureSection />
    <ModesSection />
    <Functioning />
    <!-- <Description />
    
    <PricePrev /> -->
    <!-- <Steps /> -->
  </div>
</template>

<script>
// @ is an alias to /src
import CoverConcept from '../components/CoverConcept.vue'
import Animal from '../components/Animal.vue'
import FeatureSection from '../components/FeatureSection'
import ModesSection from '../components/ModesSection'
// import Description from '../components/Description'
import Functioning from '../components/Functioning'
// import PricePrev from '../components/PricePreview'
// import Steps from '../components/Steps'

export default {
  name: 'Home',
  components: {
    CoverConcept,
    Animal,
    FeatureSection,
    ModesSection,
    // Description,
    Functioning
    // PricePrev,
    // Steps
  },
    mounted () {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' })
  },
}
</script>
