<template>
  <div>
  
    <h1>GRUNDEINSTUFUNG FÜR DEN START</h1>
    <h2>Je nach Lesestand bietet leamos drei verschiedene Grundeinstufungen (genaue Info in den FAQ), die unterschiedliche Schwerpunkte und Schwierigkeit aufweisen</h2>
    <h3>Student </h3>
    <p>    
        arbeitet an der Automatisierung von Buchstabenkombinationen
    </p>
    <h3>Praktikant </h3>
    <p>
      arbeitet an der Lesegenauigkeit und der Visualisierung von Wortbildern
    </p>
    <h3>
        Trainee 
    </h3>
    <p>
    arbeitet an der Lesegeschwindigkeit und aktiven Reproduktion der Worte

    </p>

  </div>
</template>

<script>
export default {
  name: 'Start',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/styles/variables";
 div{
        width: 70vw;
        margin: auto;
        text-align: left;
            @media screen and (max-width: $start-mobile) {
      width: 90vw;
    }
    }
</style>
