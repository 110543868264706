<template>
  <div class="summaryWrapper">
    <!-- <div class="bottomBg">
      <img src="../assets/images/designs/down-convex.svg" alt="">
    </div> -->
    <div class="content">
                <h1>Sechs Aspekte von leamos</h1>
        <div class="reason">
            <div class="number">
                <img src="../assets/images/flash.svg" alt="">
            </div>
            <div class="text">
                <h3>Blitzlesen</h3>
                <p>
                  Kurz aufblitzende Worte, Symbole und Pseudoworte verbessern rasch die Leseleistung (Anzeigezeit variiert von 250ms bis 5sec).
                </p>
            </div>
        </div>
        <div class="reason">
            <div class="number">
                <img src="../assets/images/Dash_Web.svg" alt="">
            </div>
            <div class="text">
                <h3>Kurze Trainingslisten und klare Tagesziele</h3>
                <p>
                  Jede Trainingsliste umfasst acht Aufgaben und kann in 15 bis 50 Sekunden erfüllt werden. Damit sind Aufgaben in leamos für jeden, auch mit Aufmerksamkeitsschwäche, schaffbar. Das Tagesziel wird  auf einem Tacho gut erkenntlich angezeigt.
                </p>
            </div>
        </div>
        <div class="reason">
            <div class="number">
                <img src="../assets/images/motivation.svg" alt="">
            </div>
            <div class="text">
                <h3>Motivation für den „Lesesportler“ und schneller Überblick über Fleiß und Lernfortschritt.
                </h3>
                <p>
                  Motivierendes Belohnungssystem zur Eigenmotivation gibt auch Bezugspersonen einen schnellen Überblick über Fleiß und Erfolg 
                  <br>
                  -	Diamanten sammeln, um den Erfolg zu dokumentieren.
                  <br>
                  -	Feuer am Brennen erhalten, die das Erreichen des Tagesziels zeigen. 
                  <br>
                  -	Lesepunkte mitnehmen, die Fleiß und Leseleistung sowie die kürzere Anzeigezeit dokumentieren.
                </p>
            </div>
        </div>
        <div class="reason">
            <div class="number">
                <img src="../assets/images/reps.svg" alt="">
            </div>
            <div class="text">
                <h3>Abwechslungsreiche Wiederholung baut das Gehirn für erfolgreiches Lesen um.
                </h3>
                <p>
                 Oftmalige Wiederholung und ständige Abwechslung erhöhen die Bereitschaft des Gehirns sich zu verändern und stabile Verbindungen fürs Lesen zu schaffen. 
                </p>
            </div>
        </div>
        <div class="reason">
            <div class="number">
                <img src="../assets/images/symbols.svg" alt="">
            </div>
            <div class="text">
                <h3>Symbole sind positiv besetzt 
                </h3>
                <p>
                  Durch den Einsatz von Symbolen wird das visuelle Gedächtnis aktiv trainiert.   
                </p>
            </div>
        </div>
        <div class="reason">
            <div class="number">
                <img src="../assets/images/expert.svg" alt="">
            </div>
            <div class="text">
                <h3>Leamos ist aus dem Bedarf der Praxis entstanden
                </h3>
                <p>
                  Gerade durch das Home-Schooling kommen viele mit ihrem Leseerfolg nicht weiter. leamos wurde deshalb in Diskussion mit Leseexperten und aufbauend auf neuen pädagogischen und neurologischen Konzepten entwickelt. Es soll für alle überall einsetzbar sein – alle Bildschirm-Endgeräte sind leamosfit.
                </p>
            </div>
        </div>
        <div style="margin-top: 80px;">
          <h2>Wie soll das genau funktionieren? Bringt mich das weiter? Wie macht leamos das?</h2>
        <p>
          Mit einem 14-tägigem Probetraining  kannst du gerne in die Trainingswelt von leamos eintauchen. Neben dem Trainingsablauf als Student, Praktikant oder Trainee kannst du auch das Freie Training probieren. Im Freien Training werden alle Trainingsarten mit unterschiedlicher Schwierigkeit vorgestellt und die Anzeigezeit ist einstellbar. So  erhältst du einen guten Überblick über leamos.   
        </p>
        <a href="https://app.leamos.at/registrierung/1IK4xFIHCsSlwSpgsds62M6e">
          <button class="trial-btn" >14 Tage kostenlos testen</button>
        </a>
        </div>

    </div>
    
  </div>
</template>

<script>
export default {
  name: 'FeatureSection',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/styles/variables";
    .summaryWrapper{
position: relative;
  width: 100vw;
  min-height: 300px;
  // background-color: $leamos-green-light;
  .content{
      padding-top: 1px;
      padding-bottom: 80px;
      width: 70vw;
      margin: auto;
      z-index: 2;
      @media screen and (max-width: $start-mobile) {
                    width: 90vw;
                }
      .reason{
          display: flex;
          text-align: left;
          width: 60%;
          @media screen and (max-width: 800px) {
                    width: 100%;
                }
          .number{
              font-size: 6rem;
              font-weight: bolder;
              display: flex;
              align-items: center;
              // width: 150px;
              // max-height: 200px;
              // max-width: 200px;
              width: 25%;
              img{
                //   height: 100%;
                  width: 100%;
              }
          }
          .text{
              padding-left: 20px;
              max-width: 75%;
              min-width: 200px;
              @media screen and (max-width: 800px) {
              &:nth-of-type(even){
                padding-right: 20px;
                padding-left: 0px;
              }
              }
              // &:nth-of-type(odd){
              //   padding-left: 20px;
              // }

          }
          &:nth-of-type(even){
              margin-left: auto;
              @media screen and (max-width: 800px) {
                    margin-left: 0px;
                    flex-direction: row-reverse;
                }
          }
      }
          .trial-btn{
                            margin-top: 25px;
                            background-color: #ffd000;
                            // width: 100%;
                            padding: 10px ;
                            border-radius: 5px;
                            font-weight: 800;
                            box-shadow: 1px 1px 5px  rgba(107, 106, 106, 0.8);
                            outline: none;
                            border: 0px;
                            cursor: pointer;
                            }
    //   color: white;
  }
  .bottomBg{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #f5f5f5;
    z-index: 1;
    img{
      width: 100%;
      height: 90px;
    }
  }
    }
</style>
