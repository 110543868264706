<template>
  <div class="sectionWrapper">
    <!-- <div class="top">
      <img src="../assets/images/designs/up-convex.svg" alt="" />
    </div> -->
    <div class="content">
      <h2 style="text-align: center; margin: auto">
        Lesekompetenz als Grundlage für lebenslanges Lernen
      </h2>
      <p>
        <strong>Lebenslanges Lernen</strong> benötigt hohe
        <strong>Lesekompetenz</strong>. Der PISA-Test 2018 ergab, dass 24% der
        österreichischen Jugendlichen am Ende der Pflichtschulzeit zur
        Lese-Risikogruppe gehören. Nur 7,4% der österreichischen Jugendlichen
        können Fakten von Meinungen unterscheiden. Die Stärkung der
        Lesekompetenz ist daher wichtig.
      </p>
      <h4>
        Im Folgenden wird das Konzept von leamos noch etwas genauer beschrieben
        und es wird auf weitere Aspekte eingegangen.
      </h4>

      <div class="section">
        <div class="text">
          <!-- <h2 style="margin-bottom: 0px;">Jederzeit und Überall</h2> -->
          <h3 style="margin-top: 0px">
            leamos hilft Leseanfängern, aber auch Lesern, die an der
            Leseflüssigkeit arbeiten wollen.
          </h3>
          <p>
            leamos unterstützt das Lesen lernen, sobald die meisten Buchstaben
            erkannt werden und deren Zusammenlautieren grundsätzlich gekonnt
            wird.
          </p>
          <p>
            Algorithmen arbeiten im Hintergrund und erstellen einen individuelle
            Trainingspool mit laufend angepasstem Schwierigkeitsniveau. Die
            Zusammenstellung der Trainingseinheiten für PädagogInnen oder
            Eltern.
          </p>
        </div>
        <div class="image">
          <img src="../assets/images/school_group_sm.jpg" alt="" />
        </div>
      </div>
      <div class="section">
        <div class="image">
          <img src="../assets/images/kid_home.jpg" alt="" />
        </div>
        <div class="text">
          <h3>Trainingskombination zwischen Schule und zu Hause</h3>
          <p>
            Mit einer Schullizenz können ganze Klassen oder Fördergruppen lesen
            trainieren. Die Kinder können parallel zu dem Training in der Schule
            leamos täglich zu Hause verwenden.
          </p>
          <p>
            Beispielsweise ist es möglich mit Betreutem Lesen und einigen
            Einzeltrainings in der Schule zu beginnen und dann selbstständig zu
            Hause das Tagesziel zu beenden. Und dies alles ohne Aufwand für
            Lehrer oder Eltern! Kontinuierliche Leseförderung gelingt am besten
            bei guter Zusammenarbeit zwischen Schule und zu Hause. Mit leamos
            ist ein variabler Einsatz ohne großen Aufwand möglich.
          </p>
        </div>
      </div>
      <div style="margin-top: 55px">
        <!-- Lebenslanges Lernen benötigt hohe Lesekompetenz. <strong>Der PISA-Test 2018 ergab, dass 24% der österreichischen Jugendlichen am Ende der Pflichtschulzeit zur Lese-Risikogruppe gehören.</strong>  Nur 7,4% der österreichischen Jugendlichen können Fakten von Meinungen unterscheiden.  Kontinuierliche Leseförderung gelingt am besten bei guter Zusammenarbeit zwischen Schule und zu Hause. Mit leamos ist ein variabler Einsatz ohne großen Aufwand möglich. -->
      </div>
    </div>
    <div class="bottom">
      <img src="../assets/images/designs/down-convex.svg" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "SchoolIntro",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/styles/variables";
.sectionWrapper {
  background-color: #b9eabb;
  .top {
    background-color: #f5f5f5;
    width: 100%;
    height: 120px;
    img {
      width: 100%;
      height: 120px;
    }
  }
  .bottom {
    background-color: #f5f5f5;
    width: 100%;
    height: 120px;
    img {
      width: 100%;
      height: 120px;
    }
  }
  .content {
    width: 70vw;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: $start-mobile) {
      width: 90vw;
    }
    .section {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      &:nth-child(even) {
        flex-wrap: wrap-reverse;
      }
      .image {
        display: flex;
        align-items: center;
        width: 70%;
        max-width: 500px;
        margin: auto;
        @media screen and (max-width: $small-pre-mobile) {
          width: 50%;
        }
        @media screen and (max-width: 800px) {
          width: 100%;
        }
        img {
          // max-width: 500px;
          // width: 100%;
          max-height: 250px;
          margin: auto;

          box-shadow: 1px 1px 7px #2c3e50;
          border-radius: 8px;
          margin-bottom: 10px;
        }
      }
      .text {
        width: calc(100% - 500px);
        @media screen and (max-width: $small-pre-mobile) {
          width: 45%;

          margin-right: 5%;
          &:nth-child(even) {
            margin-left: 5%;
            margin-right: 0px;
          }
        }
        @media screen and (max-width: 800px) {
          width: 100%;
        }
        text-align: center;
        display: flex;
        /* align-items: center; */
        flex-direction: column;
        justify-content: center;
      }
    }
  }
}
</style>
