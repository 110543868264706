<template>
  <div class="sectionWrapper">
    <div class="content">
        <h2>Fleiß und Erfolg beim leamos Lesetraining erkennen</h2>
        <p>
            Das Verfolgen von Fleiß und Erfolg ist auch für User - Eltern oder Pädagog*innen – jederzeit und einfach möglich. Bei jedem Login kann der Trainingserfolg eingesehen werden.
        </p>
        <p>
            Das <strong>Feuer (Streak)</strong> zeigt auf einen Blick, ob die täglichen Tagesziele erreicht wurden. Die <strong>Diamanten</strong> zeigen den Erfolg (bzw. Richtigkeit) des Trainings – eine Durchführung mit dreimal einer Richtigkeit von über 75% zeigt zwei   Diamanten. Die <strong>Lesepunkte</strong> sind eine Mischung aus Fleiß (2 Punkte für jede Durchführung) und Erfolg (0-5 Punkte für die Richtigkeit und 0-3 Punkte für die Kürze der Anzeigezeit).
        </p>
        <p>
            Möchte man einen genaueren Überblick über die Entwicklung einzelner Parameter, so kann man die Auswertung detailliert im Menü aufrufen. 
        </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Responsive",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/styles/variables";
.sectionWrapper {
  background-color: #f5f4f4bb;
  .content {
    width: 70vw;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    h2{
        text-align: center;
        width: 100%;
    }
    @media screen and (max-width: $start-mobile) {
      width: 90vw;
    }
    //   .image {
    //     display: flex;
    //     align-items: center;
    //     width: 70%;
    //     max-width: 500px;
    //     @media screen and (max-width: $small-pre-mobile) {
    //       width: 50%;
    //     }
    //     @media screen and (max-width: 800px) {
    //       width: 100%;
    //     }
    //     img {
    //       // max-width: 500px;
    //       width: 100%;
    //       max-height: 300px;
    //     object-fit: contain;
    //     }
    //   }
    //   .text {
    //     width: calc(100% - 500px);
    //     @media screen and (max-width: $small-pre-mobile) {
    //       width: 50%;
    //     }
    //     @media screen and (max-width: 800px) {
    //       width: 100%;
    //     }
    //     text-align: justify;
    //   }
  }
}
</style>
