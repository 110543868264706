<template>
  <div>
  
    <iframe class="video" src="https://www.youtube.com/embed/sEddlDax2FY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    <!-- <h3>Ganz einfach zum Lesetraining</h3>
    <h4>1. leamos User anlegen </h4>
    <h4>2. Lizenz auswählen (monatlich, jährlich)</h4>
    <h4>3. Auswahl der Startschwierigkeit (Student, Praktikant, Trainee) </h4>
    <h4>4. Loslegen</h4>
    <h4>5. Täglich trainieren und Ziele erreichen</h4>
    <p>
      Tagesziel an 5 oder 6 Tagen pro Woche erreichen: <br> 8 Trainings mit Lesepartner im Betreuten Lesen <br>
	15 Einzeltraining alleine durchführen
  <br> und jeden Tag Diamanten und Lesepunkte sammeln <br> sowie deinen Streak verbessern.

    </p> -->




  </div>
</template>

<script>
export default {
  name: 'Steps',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/styles/variables";
 div{
        width: 70vw;
        margin: auto;
        // text-align: left;
        .video{
          width: 560px;
          height: 315px;
          @media screen and (max-width: 600px) {
            width: 90vw;
            height: calc(90vw * 0.5625) ;
          }
        }
            @media screen and (max-width: $start-mobile) {
      width: 90vw;
    }
    }
</style>
