<template>
  <div class="home">
    <SchoolCover />
    <SchoolIntro />
    <SchoolTrainings />
    <SchoolDetails />
    <Insights />
    <!-- <Description />
    <Demo />
    <PricePrev /> -->
    <!-- <Steps /> -->
  </div>
</template>

<script>
// @ is an alias to /src
import SchoolCover from '../components/SchoolCover.vue'
import SchoolIntro from '../components/SchoolIntro.vue'
import SchoolTrainings from '../components/SchoolTrainings'
import SchoolDetails from '../components/SchoolDetails'
import Insights from '../components/Insights'
// import Description from '../components/Description'
// import Demo from '../components/Demo'
// import PricePrev from '../components/PricePreview'
// import Steps from '../components/Steps'

export default {
  name: 'Home',
  components: {
    SchoolCover,
    SchoolIntro,
    SchoolTrainings,
    SchoolDetails,
    Insights
    // Description,
    // Demo,
    // PricePrev,
    // Steps
  },
    mounted () {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' })
  },
}
</script>
