<template>
  <div class="sectionWrapper">
      <!-- <div class="top">
      <img src="../assets/images/designs/up-convex.svg" alt="" />
    </div> -->
    <div class="content">
       <div class="image">
        <img src="../assets/images/bd_animal.svg" alt="">
      </div>
      <div class="text">
       <h2 style="margin-bottom: 0px;">Lesen</h2>
        <h3 style="margin-top: 0px; margin-bottom: 0px;">DIE wichtigste Kulturtechnik der Menschheit</h3>
        <h3 style="margin-top: 0px;">DIE Schlüsselkompetenz in der Bildungsforschung</h3>
        <!-- <p>
          Was war das Erste, was Menschen gelesen haben? 
          Menschen lesen immer schon zunächst waren es <strong>Tierspuren</strong>! Das blitzschnelle Erkennen von Tierspuren war überlebenswichtig. 
        </p> -->
        <p>
          Lange bevor es Papier und Stift gab, haben wir Menschen bereits gelesen. Was war das Erste, was wir Menschen gelesen haben? Unsere ersten Leseerfahrungen waren mit <strong>Tierspuren</strong>! Das blitzschnelle Erkennen von Tierspuren war für uns überlebenswichtig. 
        </p>
        <p>
          Auch heute noch wird <strong>Lesen in den gleichen Gehirnregionen</strong> verarbeitet. Aufblitzende Buchstaben und Zeichen werden nebeneinander entziffert. Durch Übung erkennt unser Lesesystem bekannte Buchstabenkombinationen immer schneller. 
Für die links-rechts Unterscheidung von Buchstaben müssen Prozesse der Formkonstanz  umgebaut werden. leamos unterstützt diesen Vorgang aktiv mit durchdachten Wiederholungen und eingebauter Abwechslung.
        </p>
        <!-- <p>
          Bis heute wird <strong>Lesen in den gleichen Gehirnregionen</strong> verarbeitet. Aufblitzende Buchstaben und Zeichen werden nebeneinander decodiert. Immer schneller erkennt das System bekannte Buchstabenkombinationen.
          <br>Für die links-rechts Unterscheidung von Buchstaben müssen Prozesse der Formkonstanz verlernt werden. Dies unterstützt leamos mittels durchdachter Wiederholungen. 
        </p> -->
      </div>
     
    </div>
    <div class="bottom">
      <img src="../assets/images/designs/down-convex.svg" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Animal",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/styles/variables";
.sectionWrapper {
  background-color: #b9eabb;
  .top {
    background-color: #f5f5f5;
    width: 100%;
    height: 120px;
    img {
      width: 100%;
      height: 120px;
    }
  }
   .bottom {
    background-color: #f5f5f5;
    width: 100%;
    height: 120px;
    img {
      width: 100%;
      height: 120px;
    }
  }
  .content {
    width: 70vw;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: $start-mobile) {
      width: 90vw;
    }
    .image {
      display: flex;
      align-items: center;
      width: 40%;
      // max-width: 500px;
      @media screen and (max-width: $small-pre-mobile) {
        width: 35%;
      }
      @media screen and (max-width: 800px) {
        width: 100%;
      }
      img {
        // max-width: 500px;
        // width: 100%;
        max-height: 250px;
        margin: auto;

    //         box-shadow: 1px 1px 7px #2c3e50;
    // border-radius: 8px;
    // margin-bottom: 10px;
      }
    }
    .text {
      width: 60%;
      @media screen and (max-width: $small-pre-mobile) {
        width: 65%;
      }
      @media screen and (max-width: 800px) {
         text-align: center;
        width: 100%;
      }
      text-align: left;
          display: flex;
    /* align-items: center; */
    flex-direction: column;
    justify-content: center;
    }
  }
}
</style>
